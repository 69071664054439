import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.containerWidths.medium};
  margin-left: auto;
  margin-right: auto;

  .section {
    > div + .padded-section {
      padding-top: 30px;
    }

    > .padded-section:last-of-type {
      padding-bottom: 0;
    }
  }

  .class-title {
    margin: 30px 0 0 0;
  }

  .classInstructors-container {
    max-width: ${props => props.theme.containerWidths.small};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px 9px;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0;

    button {
      display: block;
      padding: 0 5px;
      text-align: center;
      color: #000;

      .gatsby-image-wrapper,
      .gatsby-image-wrapper img {
        border-radius: 50%;
      }

      .gatsby-image-wrapper {
        width: 100% !important; // override
        height: auto !important; // override
        margin-bottom: 12px;

        picture {
          img {
            position: relative !important;
          }
        }
      }

      .speaker--title {
        margin-top: 4px;
        margin-bottom: 4px;
      }
    }

  @media screen and (min-width: ${(props) => props.theme.responsive.small}) {
     button {
      display: block;
      padding: 0 20px;
      text-align: center;
     }
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    .section {
      > div + .padded-section {
        padding-top: 30px;
      }
    }

    section {
      + .padded-section {
        padding-top: 0;
      }
    }

    max-width: ${props => props.theme.containerWidths.smallMedium};
    grid-template-columns: repeat(auto-fit, minmax(164px, 200px));
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    .classes {
      > div {
        + div {
          margin-top: 60px;
        }
      }
    }
  }
`

const MakeupClasses = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulReunionClass(
        sort: { fields: [order], order: [ASC] }
      ) {
        edges {
          node {
            id
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
            day
            time
            title
            where
          }
        }
      }
    }
  `)

  const makeupClasses = data.allContentfulReunionClass.edges

  return (
    <Wrapper>
      <section className="section">
        <h3 style={{ marginBottom: '20px' }} className="headline--reunion center-align">Makeup Classes</h3>
        <div>
          {makeupClasses.map(makeupClass => (
            <div key={makeupClass.node.id} style={{ marginBottom: '30px', paddingLeft: '10px' }}>
              <h4 key={makeupClass.node.id} className="headline--small" style={{ margin: '0' }}>{makeupClass.node.title}</h4>
              <p key={makeupClass.node.id}>
                {makeupClass.node.description.childMarkdownRemark.rawMarkdownBody}
              </p>
            </div>
          ))}
        </div>
      </section>
    </Wrapper>
  )
}

export default MakeupClasses
